const fabStyle = {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
};

const pbStyle = {
    margin: 0,
    top: 'auto',
    left: 0,
    bottom: 0,
    right: 'auto',
    position: 'fixed',
    paddingBottom: 20
};

export {
    fabStyle,
    pbStyle
};