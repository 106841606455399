import React, { useEffect, useMemo, useState } from 'react';
import _ from "lodash";
import { MapContainer, TileLayer, Popup, Marker } from "react-leaflet";

import { Link } from "gatsby"
import FullscreenWrapper from './FullscreenWrapper';


import { getEntityUrl } from "../util";
import 'leaflet/dist/leaflet.css';



const Map = (props) => {
    const { component, entity, pageContext, fullscreenMode } = props;
    const { defaultLocale } = pageContext;

    const bounds = component.southWestBoundary && component.northEastBoundary &&
        [[component.northEastBoundary.latitude, component.northEastBoundary.longitude],
        [component.southWestBoundary.latitude, component.southWestBoundary.longitude]
        ];

    const [Container, setContainer] = useState(React.Fragment);
    const [map, setMap] = useState(null)

    useEffect(() => {
        setContainer(window === undefined ? React.Fragment : MapContainer);
    }, [setContainer]);

    useEffect(() => {//required to get icons offline and have ssr working
        const L = require("leaflet");

        delete L.Icon.Default.prototype._getIconUrl;

        L.Icon.Default.mergeOptions({
            iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png").default,
            iconUrl: require("leaflet/dist/images/marker-icon.png").default,
            shadowUrl: require("leaflet/dist/images/marker-shadow.png").default
        });
    }, []);

    const mapProps = useMemo(() => {
        return Container === MapContainer ? {
            className: 'erwinmap',
            center: [component.center.latitude, component.center.longitude],
            zoom: component.zoom || 13,
            minZoom: component.minZoom || 10,
            maxZoom: component.maxZoom || undefined,
            scrollWheelZoom: true,
            style: { width: '100%', height: '80vh' },
            maxBounds: bounds,
            ref: setMap,
        } : {};
    }, [Container]);

    useEffect(() => map && map.invalidateSize(), [map, fullscreenMode]);

    return <FullscreenWrapper {...props} buttonStyle={{ background: '#0009', bottom: 0 }}>
        <Container {...mapProps} key='mapContainer'>
            {Container === MapContainer &&
                <>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        key='tileLayer'
                    />
                    {entity.strapiChildren.map((c, n) => {
                        const { Roles } = c;
                        const role = _.find(Roles, { strapi_component: 'role.map-child' });

                        return role !== undefined &&
                            <Marker position={[role.position.latitude, role.position.longitude]} key={c.identifier}
                                eventHandlers={{
                                    mouseover: (e) => {
                                        e.target.openPopup();
                                    },
                                    mouseout: (e) => {
                                        setTimeout(() => {
                                            e.target.closePopup();
                                        }, 1000);
                                    }
                                }}
                            >
                                <Popup  >
                                    <Link to={getEntityUrl({ entity: c, defaultLocale })}>
                                        {role.label || entity.title || entity.identifier}
                                    </Link>
                                </Popup>
                            </Marker>
                    })
                    }
                </>
            }
        </Container >
    </FullscreenWrapper>
}

export default Map;