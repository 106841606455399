

import React, { Fragment } from "react";
import { styled } from '@mui/material/styles';
import _ from "lodash";

import { List, ListItem, ListItemText, Divider, ListItemAvatar, Avatar } from '@mui/material'
import MuiLink from '@mui/material/Link';


import { Link as GatsbyLink } from "gatsby"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"

import { css } from "@emotion/react"

import { getEntityUrl } from "../util";

const PREFIX = 'EntityList';

const classes = {
    root: `${PREFIX}-root`,
    inline: `${PREFIX}-inline`,
    image: `${PREFIX}-image`
};

const StyledList = styled(List)((
    {
        theme
    }
) => ({
    [`&.${classes.root}`]: {
        backgroundColor: theme.palette.background.paper,
    },

    [`& .${classes.inline}`]: {
        display: 'inline',
    },

    [`& .${classes.image}`]: {
        width: '150px',
        marginRight: '12px',
        verticalAlign: 'middle',
    }
}));

const Link = React.forwardRef(function Link(props, ref) {
  return <MuiLink component={GatsbyLink} ref={ref} {...props} />;
});

const EntityList = ({ entity, pageContext }) => {
    const { defaultLocale } = pageContext;

    const { strapiChildren: children } = entity;


    return (
        <StyledList className={classes.root}>
            <Divider component="li" />

            {children.map(entity => {
                const listRole = _.find(entity.Roles, { strapi_component: 'role.list-child' });
                const title1 = listRole?.line1 || entity.title || '';
                const title2 = listRole?.line2 || false;
                //const title3 = listRole?.line3 || false;

                const image = entity.smallImage ? getImage(entity.smallImage.localFile) : null;

                return (
                    <Fragment key={entity.identifier}>
                        <Link to={getEntityUrl({ entity, defaultLocale })} 
                            sx={{
                              textDecoration: 'none',
                              color: 'inherit',
                            }}
                        >
                            <ListItem key={entity.identifier} button>
                                <ListItemAvatar className={classes.image}>
                                    { image !== null ? 
                                    <GatsbyImage image={image} alt={title1} layout="fullWidth" /> : <></> } 
                                </ListItemAvatar>
                                <ListItemText primary={title1} secondary={<Fragment>{title2}</Fragment>} />
                            </ListItem>
                        </Link>
                        <Divider component="li" />
                    </Fragment>
                );
            })}
        </StyledList>
    );

};

export default EntityList;
